<template>
    <div v-if='model && !model.is_null' :key='"posts_t_"+lang'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class=' col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div><span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    placeholder="Stato di pubblicazione" /></div>
                            <div class='ml-5'><span class="publish_state">Data di pubblicazione </span>
                                <Calendar
                                    :showTime="true"
                                    inputId="pub_at"
                                    v-model="v$.model.pub_at.$model"
                                    dateFormat="dd/mm/yy"
                                    :class="{'p-invalid': v$.model.pub_at.$invalid && v$.model.pub_at.$dirty}"
                                /></div>
                        </template>
                    </Toolbar>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='t_slug'
                                   type='text'
                                   v-model="v$.model.slug_trans.$model"
                                   :class="{'p-invalid': v$.model.slug_trans.$invalid && v$.model.slug_trans.$dirty}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug_trans = sluggify(model.slug_trans)'/>
                        <Button icon="pi pi-angle-double-down" class="p-button-primary" title='Eredita' @click='model.slug_trans = row.slug'/>
                        <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.slug_trans = sluggify(model.title)'/>
                        <label for='t_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$errors.length}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='catchline'
                                   type='text'
                                   v-model="v$.model.catchline.$model"
                                   :class="{'p-invalid': v$.model.catchline.$errors.length}"
                        />
                        <label for='catchline'>Catchline</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.description'
                        :key='"cont_bs_body_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Descrizione</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.guest_desc'
                        :key='"cont_bs_gd_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Descrizione per SEO</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImg'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='mainImg'
                                   type='text'
                                   v-model="model.cover_url"
                        />
                        <label for='mainImg'>Immagine principale</label>
                    </span>
                    <div v-if='model.cover_url' class='text-center mt-2'>
                        <img :src='model.cover_url' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='alt'
                                   type='text'
                                   v-model="model.alt"
                        />
                        <label for='alt'>Didascalia Immagine</label>
                    </span>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/BookSeries');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'

import tools from '../../../libs/tools';
import model from './model';

export default {
    data(){
        return {};
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    beforeUnmount() {
        this.uppyImg.close()
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        lastUpdateDate() {
            return tools.time.lastUpdateFromTs(this.model['updated_at']);
        },
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }

            this.saveTranslation({
                data: model.formatTranslation(this.model),
                parent_id: model.translationParentId(this.model)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(model.parseTranslation(mod.translations[this.lang]), base);
                return;
            }

            this.model = base;
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: String
        }
    },
    mixins: [
        Navigations, Notifications, Uploader
    ]
}
</script>
