import tools from '../../../libs/tools';
import { required, minLength } from '@vuelidate/validators'

const model = {
    id: "",
    slug: "",
    books: [],
    translations: {}
};

const translation = {
    slug_trans: "",
    bsid: "",
    l: "",
    alt: "",
    catchline: "",
    cover_url: "",
    guest_desc: "",
    description: "",
    publish_state: "",
    pub_at: null,
    title: ""
};

export default {
    ...tools.models.apiCommons(model, translation, "bsid"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            books: {
                required,
                minLength: minLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            slug_trans: {
                required,
                slug: tools.models.validators.SLUG
            },
            title: { required },
            catchline: { required },
            publish_state: { required },
            pub_at: {
                validDate: tools.models.validators.PUBLISH_DATE
            }
        }
    },
    uploads: [
        { el: "uppyImg", target: "cover_url", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]}
    ]
};
